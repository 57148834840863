

var sw = window.innerWidth;
var sh = window.innerHeight;
var header = document.querySelector("header");
var sticky = 0;

// Font Resizer-------------------Start
function fontResizer() {
  var perc = parseInt(sw) / 118.9375;
  document.body.style.fontSize = perc + "px";
}

// Loader-------------------Start
function pageLoader() {
  setTimeout(function () {
    document.querySelector("html").classList.remove("loadjs");
  }, 800);
}

// Navigation slide Active on a mobile
// if (document.querySelector("#toggle")) {
//   let toggleMenu = document.querySelector("#toggle");
//   let htmlEl = document.querySelector("html");

//   toggleMenu.addEventListener("click", (event) => {
//     console.log("this", event);
//     let _this = event.target;
//     _this.classList.toggle("active");
//     document.querySelector(".navigation").classList.toggle("open");
//     htmlEl.classList.toggle("overflow-hidden");
//     if (document.querySelector(".navigation").classList.contains('open')) { } else {
//       document.querySelectorAll(".mobileToggle").forEach(item => {
//         item.classList.remove('open--menu');
//         item.nextElementSibling.classList.remove('openMegaMenu')
//       })

//     }
//     document.querySelector(".inner-menu").classList.toggle("openMegaMenu");

//   });
// }


// function setNavigation() {
//   let current_location = location.pathname.split("/")[1];
//   let current_location_ar = location.pathname.split("/ar/")[1];
//   let lang = document.documentElement.getAttribute("lang")

//   if (current_location === "") return;
//   let menu_items = document.querySelector("header").getElementsByTagName("a");
//   if (lang == "en") {
//     for (let i = 0, len = menu_items.length; i < len; i++) {
//       if (menu_items[i].getAttribute("href").indexOf(current_location) !== -1) {
//         menu_items[i].classList.add("active");
//       }
//     }
//   }

//   if (lang == "ar") {
//     for (let i = 0, len = menu_items.length; i < len; i++) {
//       if (menu_items[i].getAttribute("href").indexOf(current_location_ar) !== -1) {
//         menu_items[i].classList.add("active");
//       }
//     }
//   }
// }
// setNavigation();

// function setNavigation() {
//   let current_location = location.pathname.split("/")[1];
//   if (current_location === "") return;
//   let menu_items = document.querySelector("header").getElementsByTagName("a");

//   for (let i = 0, len = menu_items.length; i < len; i++) {
//     if (menu_items[i].getAttribute("href").indexOf(current_location) !== -1) {
//       menu_items[i].classList.add("active");
//     }
//   }
// }
// setNavigation();


// Landscape Mode off
function landscapeModeOff() {
  if (sh < 450 && sw > 480 && sw < 820) {
    document.getElementById("portrait-warnning").style.display = "flex";
  } else {
    document.getElementById("portrait-warnning").style.display = "none";
  }
}

// Sticky Header----------------Start
// function stickyHeader() {
//   if (window.pageYOffset > sticky) {
//     header.classList.add("sticky");
//   } else {
//     header.classList.remove("sticky");
//   }
// }

// Window on Load-----------------Start
window.onload = function () {
  pageLoader();
  if (sw > 1199) {
    fontResizer();
  }
  // SVG Convert Code
  // let svgs = document.querySelectorAll("img.svg-convert");
  // svgs.forEach((el) => {
  //   const imgID = el.getAttribute("id");
  //   const imgClass = el.getAttribute("class");
  //   const imgURL = el.getAttribute("src");

  //   fetch(imgURL)
  //     .then((response) => response.text())
  //     .then((data) => {
  //       const parser = new DOMParser();
  //       const xmlDoc = parser.parseFromString(data, "text/html");
  //       let svg = xmlDoc.querySelector("svg");
  //       if (typeof imgID !== "undefined") {
  //         svg.setAttribute("id", imgID);
  //       }

  //       if (typeof imgClass !== "undefined") {
  //         svg.setAttribute("class", imgClass + " svg-converted");
  //       }

  //       svg.removeAttribute("xmlns:a");

  //       el.parentNode.replaceChild(svg, el);
  //     });
  // });

  // setTimeout(function () {
  //   let innerLink = document.querySelectorAll('.js-inner')
  //   innerLink.forEach(el => el.addEventListener('click', e => functionforscroll(e.target.getAttribute('data-id'))))
  // }, 10000)


};

// Window On Resize-----------------Start
window.addEventListener("resize", (event) => {
  sw = window.innerWidth;
  sh = window.innerHeight;
  landscapeModeOff();
  if (sw > 1025) {
    if (sw < 1400 && sw > 1300 && sh > 900) { } else {
      fontResizer();
    }
  }
});

// Window On Scroll-----------------Start
// window.addEventListener("scroll", (event) => {
//   stickyHeader();
// });

// if (document.querySelector(".home-hero__heroSlider")) {
//   var heroSlider = new Swiper(".home-hero__heroSlider", {
//     slidesPerView: 1,
//     allowTouchMove: true,
//     loop: false,
//     on: {
//       init: function (e) {
//         const slide = e.slides[0];
//         let iframeslide
//         addIFrame(e.slides, 0);
//       },
//       slideChange: function ({
//         realIndex,
//         slides
//       }) {
//         addIFrame(slides, realIndex);
//       }
//     }
//   });
// }

// function addIFrame(slide, index) {
//   slide.forEach(item => {
//     let x = item.children[0].getElementsByClassName('iframeVidz');
//     if (x.length) {
//       x[0].remove()
//     }
//   })
//   let dsrc1 = document.querySelector(".iframeData").getAttribute("data-src");
//   let iFrame1 =
//     '<iframe class="iframeVidz" src="' +
//     dsrc1 +
//     '" style=""></iframe>';
//   setTimeout(function () {
//     let a = slide[index].querySelector(".iframeData")
//     if (a) {
//       a.innerHTML = iFrame1
//     }
//   }, 300);
// }


// const elts = {
//   text1: document.getElementById("text1"),
//   text2: document.getElementById("text2")
// };

// const texts = [
//   "Support",
//   "Stability",
//   "Growth",
//   "Prosperity",
//   "Opportunity",
//   "Innovation",
//   "Productivity",
//   "Impact",
//   ""
// ];

// let btext = document.querySelectorAll('.blurtext span')
// setInterval(function () {
//   btext.forEach((item) => item.classList.remove('past'))
//   btext.forEach((item) => {
//     if (item.classList.contains('active')) {
//       item.classList.add('past');
//       item.classList.remove('active');
//     }
//   })
//   let j = 0;
//   btext.forEach((item, i) => {
//     if (j == 1) {
//       j = 0;
//       item.classList.add('active');
//     }
//     if (btext[i].classList.contains('past')) {
//       j = 1;
//     }
//   })
//   if (j == 1) {
//     btext[0].classList.add('active')
//   }
// }, 2800);

// const morphTime = 1;
// const cooldownTime = 0.25;

// let textIndex = texts.length - 1;
// let time = new Date();
// let morph = 0;
// let cooldown = cooldownTime;

// elts.text1.textContent = texts[textIndex % texts.length];
// elts.text2.textContent = texts[(textIndex + 1) % texts.length];

// function doMorph() {
//   morph -= cooldown;
//   cooldown = 0;

//   let fraction = morph / morphTime;

//   if (fraction > 1) {
//     cooldown = cooldownTime;
//     fraction = 1;
//   }

//   setMorph(fraction);
// }

// function setMorph(fraction) {
//   elts.text2.style.filter = `blur(${Math.min(10 / fraction - 6, 5)}px)`;
//   elts.text2.style.opacity = `${Math.pow(fraction, 1.2) * 100}%`;

//   fraction = 1 - fraction;
//   elts.text1.style.filter = `blur(${Math.min(10 / fraction - 6, 5)}px)`;
//   elts.text1.style.opacity = `${Math.pow(fraction, 1.4) * 100}%`;

//   elts.text1.textContent = texts[textIndex % texts.length];
//   elts.text2.textContent = texts[(textIndex + 1) % texts.length];
// }

// function doCooldown() {
//   morph = 0;

//   elts.text2.style.filter = "";
//   elts.text2.style.opacity = "80%";

//   elts.text1.style.filter = "";
//   elts.text1.style.opacity = "50%";
// }

// function animate() {
//   requestAnimationFrame(animate);

//   let newTime = new Date();
//   let shouldIncrementIndex = cooldown > 0;
//   let dt = (newTime - time) / 2000;
//   time = newTime;

//   cooldown -= dt;

//   if (cooldown <= 0) {
//     if (shouldIncrementIndex) {
//       textIndex++;
//     }

//     doMorph();
//   } else {
//     doCooldown();
//   }
// }

// animate();


// document.addEventListener("DOMContentLoaded", function () { // On DOM Load initiate the effect
//   // if (textArray.length) setTimeout(type, newTextDelay + 250);
//   if (tabscontent && tabscontent.length) {
//     tabscontent[0].style.display = "block";
//     spans[0].classList.add("active");
//   }
// });

// let dots;

// let tabscontent = document.querySelectorAll(".tabs");
// const setActive = (el) => {
//   [
//     ...el.parentElement.parentElement.parentElement.children,
//   ].forEach((sib, i) => {
//     sib.querySelectorAll(".tab-list").forEach((itemsib, i) => {
//       itemsib.classList.remove("active");
//     });
//   });

//   tabscontent.forEach((contentsib) => {
//     contentsib.style.display = "none";
//   });

//   el.classList.add("active");
//   let activetabcontentid = el.getAttribute("data-src");
//   let tabc = document.getElementById(activetabcontentid);
//   if (tabc) {
//     tabc.style.display = "block";
//   }
//   let itemx = el.getAttribute("data-src");
//   let dots = el.parentElement.parentElement.getElementsByClassName('dots');
//   dots[0].querySelectorAll('.dot').forEach(x => {
//     x.classList.remove('active');
//   })
//   dots[0].querySelector(`[data-src=${itemx}]`).classList.add('active');
//   return dots;
// };

// let spans = [...document.body.querySelectorAll(".tab-list")];
// spans.forEach((el) => el.addEventListener("click", (e) => setActive(el)));




// function functionforscroll(id) {
//   var getMeTo = document.getElementById(id);
//   const yOffset = -20;
//   const y = getMeTo.getBoundingClientRect().top + window.pageYOffset + yOffset;
//   setTimeout(function () {
//     window.scrollTo({
//       top: y,
//       behavior: 'smooth'
//     });
//     // getMeTo.scrollIntoView();
//   }, 500)
//   var uri = window.location.toString();
//   if (uri.indexOf("#") > 0) {
//     var clean_uri = uri.substring(0, uri.indexOf("#"));
//     window.history.replaceState({}, document.title, clean_uri);
//   }
// }


// let toggleFooter = document.querySelectorAll(".toggle-btn");


// if (sw < 767) {
//   if (toggleFooter.length > 0) {
//     for (let i = 0; i < toggleFooter.length; i++) {
//       const setActive = (el) => {
//         let bol = el.classList.contains('opened');
//         [...el.parentElement.parentElement.children].forEach((sib) => {
//           sib.children[0].classList.remove("opened");
//           let panel = sib.children[1];
//           panel.style.maxHeight = null;
//           panel.classList.remove("opened")
//         });
//         if (bol == false) {
//           el.classList.add("opened");
//           let panel = el.nextElementSibling;
//           panel.classList.add("opened")
//           panel.style.maxHeight = panel.scrollHeight + "px";
//         }
//       };
//       let spans = [...toggleFooter[i].querySelectorAll(".toggle-btn > p")];
//       spans.forEach((el) => el.addEventListener("click", (e) => setActive(el)));
//     }
//   }
// }
//  https://github.com/piersrueb/inViewport

// const inViewport = (elem) => {
//   let allElements = document.getElementsByTagName(elem);
//   let windowHeight = window.innerHeight - 500;
//   const elems = () => {
//     for (let i = 0; i < allElements.length; i++) { //  loop through the sections
//       let viewportOffset = allElements[i].getBoundingClientRect(); //  returns the size of an element and its position relative to the viewport
//       let top = viewportOffset.top; //  get the offset top
//       if (top < windowHeight) { //  if the top offset is less than the window height
//         allElements[i].classList.add('in-viewport'); //  add the class
//       } else {
//         allElements[i].classList.remove('in-viewport'); //  remove the class
//       }
//     }
//   }
//   elems();
//   window.addEventListener('scroll', elems);
// }

// inViewport('section'); //  run the function on all section elements


// if (sw < 1200) {
//   let mobileToggle = document.querySelector(".mobileToggle,.has-child-menu");

//   mobileToggle.addEventListener("click", (event) => {
//     document.querySelector(".mobileToggle").classList.toggle("open--menu");
//     document.querySelector(".inner-menu").classList.toggle("openMegaMenu");
//   });
// }


// function functionforscroll(id) {
//   var getMeTo = document.getElementById(id);
//   const yOffset = -180;
//   const y = getMeTo.getBoundingClientRect().top + window.pageYOffset + yOffset;
//   setTimeout(function () {
//     window.scrollTo({
//       top: y,
//       behavior: 'smooth'
//     });
//   }, 500)
//   var uri = window.location.toString();
//   if (uri.indexOf("#") > 0) {
//     var clean_uri = uri.substring(0, uri.indexOf("#"));
//     window.history.replaceState({}, document.title, clean_uri);
//   }
// }

// let aa = document.querySelector(".scroll-down");

// aa.addEventListener("click", (event) => {
//   setTimeout(function () {
//     var scrollTop =
//       window.pageYOffset !== undefined ?
//       window.pageYOffset :
//       (
//         document.documentElement ||
//         document.body.parentNode ||
//         document.body
//       ).scrollTop;
//     var headerHeight = header.offsetHeight;

//     var actualHeight = scrollTop - headerHeight;
//     console.log("scrollTOpVal", actualHeight);
//     window.scrollTo(0, actualHeight + 5);
//   }, 1000);
// });